"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const context_1 = require("components/context");
const services_1 = require("services");
const model_1 = require("constants/model");
const config_1 = require("types/api/response/config");
const useConfig = () => {
    const [configData, setConfigData] = (0, react_1.useState)();
    const authContext = (0, react_1.useContext)(context_1.AuthContext);
    (0, react_1.useEffect)(() => {
        const onSuccess = (response) => {
            //configuramos los items de tipo padre
            response?.data?.forEach(item => {
                if (item.id == model_1.ConfigMenu.MY_ORDERS) {
                    item.state = config_1.State.OPEN;
                    //item.type = Type.PARENT;
                }
                if (item.id == model_1.ConfigMenu.MY_ORDERS__OPEN_ORDERS) {
                    item.parentId = model_1.ConfigMenu.MY_ORDERS;
                    item.state = config_1.State.OPEN;
                    item.type = config_1.Type.CHILD;
                }
                if (item.id == model_1.ConfigMenu.MY_ORDERS__ORDERS_HISTORY) {
                    item.parentId = model_1.ConfigMenu.MY_ORDERS;
                    item.state = config_1.State.OPEN;
                    item.type = config_1.Type.CHILD;
                }
            });
            const items = response?.data;
            //Agregamos todos los elementos que faltan para poder probar localmente
            if (false && __DEV__ /*&& !__DEBUG_CLOUD__*/) {
                if (items.find(item => item.id == model_1.ConfigMenu.SUPPORT) == undefined)
                    items.push({
                        id: model_1.ConfigMenu.SUPPORT,
                        name: "Support",
                    });
                if (items.find(item => item.id == model_1.ConfigMenu.PLANNING) == undefined)
                    items.push({
                        id: model_1.ConfigMenu.PLANNING,
                        name: "Planning",
                    });
                if (items.find(item => item.id == model_1.ConfigMenu.BTU_GESTION) == undefined)
                    items.push({
                        id: model_1.ConfigMenu.BTU_GESTION,
                        name: "Gestión de BTU",
                    });
                if (items.find(item => item.id == model_1.ConfigMenu.BTU_SALE) == undefined)
                    items.push({
                        id: model_1.ConfigMenu.BTU_SALE,
                        name: "Venta de BTU",
                    });
                if (items.find(item => item.id == model_1.ConfigMenu.BTU_HISTORY) == undefined)
                    items.push({
                        id: model_1.ConfigMenu.BTU_HISTORY,
                        name: "Historial de BTU",
                    });
                if (items.find(item => item.id == model_1.ConfigMenu.BTU_DISCOUNT) == undefined)
                    items.push({
                        id: model_1.ConfigMenu.BTU_DISCOUNT,
                        name: "BTU config discount",
                    });
            }
            setConfigData(items);
        };
        const onFailSessionExpired = () => {
            authContext?.signOut?.();
        };
        (0, services_1.doConfig)({
            callback: {
                onSuccess,
                onFailSessionExpired,
            },
        });
    }, []);
    return [configData, setConfigData];
};
exports.default = useConfig;
