import ModalQuoteDiscount from "./ModalQuoteDiscount";
import ModalQuoteGenerated from "./ModalQuoteGenerated";
import ModalSelectWarehouse from "./ModalSelectWarehouse";
import ModalSelectAddress from "./ModalSelectAddress";
import ModalSelectClient from "./ModalSelectClient";
import ModalSelectTransportCompany from "./ModalSelectTransportCompany";
import ModalPurchaseSummary from "./ModalPurchaseSummary";
import ModalAccountAddresses from "./ModalAccountAddresses";
import ModalAccountTransportCompanies from "./ModalAccountTransportCompanies";
import ModalInvoiceFilters from "./ModalInvoiceFilters";
import ModalMessage from "./ModalMessage";
import ModalSelectSupportIncidentSetup from "./ModalSelectSupportIncidentSetup";
import ModalChat from "./ModalChat";
import ModalImage from "./ModalImage";
import ModalBanner from "./ModalBanner";
import ModalDeuda from "./ModalDeuda";
import ModalSelectPromotionReason from "./ModalSelectPromotionReason";
import ModalSobreDiscount from "./ModalSobreDiscount";

export {
	ModalQuoteDiscount,
	ModalQuoteGenerated,
	ModalSelectWarehouse,
	ModalSelectAddress,
	ModalSelectClient,
	ModalSelectTransportCompany,
	ModalPurchaseSummary,
	ModalAccountAddresses,
	ModalAccountTransportCompanies,
	ModalInvoiceFilters,
	ModalMessage,
	ModalSelectSupportIncidentSetup,
	ModalChat,
	ModalImage,
	ModalBanner,
	ModalDeuda,
	ModalSelectPromotionReason,
	ModalSobreDiscount,
};
