export async function fetchWithTimeout(resource, options = {}) {
	const {timeout = 8000} = options;

	/*const controller = new AbortController();
	const id = setTimeout(() => controller.abort(), timeout);
	const response = await fetch(resource, {
		...options,
		signal: controller.signal,
	});
	clearTimeout(id);
	return response;*/

	const req = new Request(resource, options);
	const res = await fetch(req);

	// timeout a request
	setTimeout(function () {
		req.abort(); // reject the fetching process
	}, timeout);
	return res;
}
