import { StyleSheet } from "react-native";
import Colors from "./colors";
import Sizes from "./sizes";

const _Styles = {
    btn: {
        minHeight: Sizes.btnMinHeight,
        paddingVertical: 10,
        paddingHorizontal: 25,
        borderRadius: 10,
    },
    btnSm: {
        minHeight: 30,
        paddingVertical: 5,
        paddingHorizontal: 15,
        borderRadius: 6
    },
    btnIcon: {
        width: 15,
        height: 15
    },
    btnIconSm: {
        width: 12,
        height: 12
    },
    box: {
        backgroundColor: "white",
        borderRadius: Sizes.configRadius,
    }
};

const Styles = StyleSheet.create({ 
    ..._Styles,
    btnPrimary: {
        ..._Styles.btn,
        backgroundColor: Colors.colorBtnPrimaryBg,
        boxShadow: "0px 5px 4px rgba(253, 241, 3, 0.3)",
        
    },
    btnSecondary: {
        ..._Styles.btn,
        backgroundColor: "black",
        boxShadow: null,
    },
    btnIconPrimary: {
        ..._Styles.btnIcon,
        tintColor: Colors.colorBtnPrimaryIcon
    },
    btnIconSecondary: {
        ..._Styles.btnIcon,
        tintColor: Colors.colorBtnSecondaryIcon
    },
});

export default Styles;