//export const URL_BASE = "http://190.116.52.115:80";
//export const URL_BASE = url;
export const URL_BASE = __DEV__
	? __DEBUG_CLOUD__
		? "https://miews.enerjet.com.pe"
		: "http://wsenerjet.hostper.com:80"
	: "https://ws.enerjet.com.pe:443";

// AUTH
export const BASIC_AUTH_USER = "fX2oN9rH5xC3jD4t";
export const BASIC_AUTH_PASSWORD = "qR3uP8qH1sK5uK2g";
export const HEADER_BASIC_AUTH = {
	Authorization:
		"Basic " + window.btoa("fX2oN9rH5xC3jD4t" + ":" + "qR3uP8qH1sK5uK2g"),
};

// WS STATUS
export const WS_STATUS_SUCCESS = 99;
export const WS_STATUS_FAIL = 0;

// WS REASONS
export const REASON_SESSION_EXIPIRED = 999;

export default {
	URL_BASE,
	BASIC_AUTH_USER,
	BASIC_AUTH_PASSWORD,
	HEADER_BASIC_AUTH,
	WS_STATUS_SUCCESS,
	WS_STATUS_FAIL,
	REASON_SESSION_EXIPIRED,
};
