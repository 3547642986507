"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const native_base_1 = require("native-base");
const theme_1 = require("theme");
const ModalPurchaseSummary = props => {
    const { purchaseResponse, onOkPress, ...rest } = props;
    const getStockTxt = (item) => {
        //if (getUserTypeFromUrl() == UserType.RV)
        //	return `${Str.stock}: ${product?.stock ?? ""}`;
        //else if (getUserTypeFromUrl() == UserType.SN) {
        if ((item?.stock ?? 0) > 0)
            return "Con stock";
        else
            return "Por agotarse";
        //}
    };
    const renderItem = ({ item }) => (<native_base_1.HStack mt="10" flexDirection="row" alignItems="center">
			<native_base_1.Text py={10} flex="1" mx="10" variant="body4">
				{item.nombre || ""}
			</native_base_1.Text>
			{item.stockAlert == true ? (<native_base_1.Text style={{
                backgroundColor: item.stockAlertColor,
                borderRadius: theme_1.Sizes.configRadiusSm,
                paddingVertical: 3,
                paddingHorizontal: 10,
            }} px={{
                base: 7,
                md: 13,
            }} variant="body6">
					{`${item?.stockAlertReason ?? ""} ${item?.stockAlertFecha ?? ""}`}
				</native_base_1.Text>) : (<native_base_1.Text style={{
                backgroundColor: (item?.stock ?? 0) > 0 ? theme_1.Colors.colorLimeGreen1 : theme_1.Colors.colorVividRed1,
                color: "white",
                borderRadius: theme_1.Sizes.configRadiusSm,
                paddingVertical: 3,
            }} px={{
                base: 7,
                md: 13,
            }} variant="body6">
					{getStockTxt(item)}
				</native_base_1.Text>)}
		</native_base_1.HStack>);
    const renderItemMerch = ({ item }) => (<native_base_1.HStack mt="10" flexDirection="row" alignItems="center">
			<native_base_1.Text py={10} flex="1" mx="10" variant="body4">
				{item.nombre || ""}
			</native_base_1.Text>
		</native_base_1.HStack>);
    return (<native_base_1.Modal {...rest}>
			<native_base_1.Modal.Content>
				<native_base_1.Modal.CloseButton />
				{!purchaseResponse?.msg && <native_base_1.Modal.Header>{theme_1.Str.ordersRegistered}</native_base_1.Modal.Header>}

				<native_base_1.Modal.Body>
					{purchaseResponse?.msg && (<native_base_1.Text mt="25" variant="body23" textAlign="center" color={purchaseResponse?.msg ? "colorVividRed1" : "colorTextPrimary"}>
							{purchaseResponse?.msg}
						</native_base_1.Text>)}

					<native_base_1.FlatList data={purchaseResponse?.stock} renderItem={renderItem}/>
					{(purchaseResponse?.merch ?? []).length > 0 && (<>
							<native_base_1.Text variant="h3" mt={20} mb={10} display={(purchaseResponse?.merch?.length ?? 0) > 0 ? "flex" : "none"}>
								Regalos agregados
							</native_base_1.Text>
							<native_base_1.FlatList data={purchaseResponse?.merch} renderItem={renderItemMerch}/>
						</>)}
				</native_base_1.Modal.Body>
				<native_base_1.Modal.Footer>
					<native_base_1.Button flex={1} onPress={onOkPress}>
						{theme_1.Str.understood}
					</native_base_1.Button>
				</native_base_1.Modal.Footer>
			</native_base_1.Modal.Content>
		</native_base_1.Modal>);
};
exports.default = ModalPurchaseSummary;
