import {API, StorageKey} from "constants";
import AsyncStorage from "@react-native-async-storage/async-storage";

const doPedidoHistorial = async ({callback}) => {
	const loginDataStr = await AsyncStorage.getItem(StorageKey.LOGIN_DATA);
	const loginDataJSON = JSON.parse(loginDataStr);

	const clientDataStr = await AsyncStorage.getItem(StorageKey.CLIENT_DATA);
	const clientDataJSON = JSON.parse(clientDataStr);

	var formData = new FormData();
	formData.append(
		"data",
		JSON.stringify({
			op: "pedidoshistorial",
			token: loginDataJSON.token,
			data: {
				uid: clientDataJSON?.id,
			},
		}),
	);

	callback?.onLoading?.(true);
	fetch(API.URL_BASE, {
		method: "POST",
		headers: {
			...API.HEADER_BASIC_AUTH,
		},
		body: formData,
	})
		.then(response => response?.json())
		.then(json => {
			switch (json.status) {
				case API.WS_STATUS_SUCCESS:
					callback?.onSuccess(json);
					break;

				default:
					if (json?.reason == API.REASON_SESSION_EXIPIRED)
						callback?.onFailSessionExpired?.();
					callback?.onFail?.(json.msg);
					break;
			}
			callback?.onLoading?.(false);
		})
		.catch(error => {
			console.error(error);
			callback?.onFail("Error!");
			callback?.onLoading?.(false);
		});
};

export default doPedidoHistorial;
