"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_1 = require("react-native");
const native_base_1 = require("native-base");
const theme_1 = __importStar(require("theme"));
const services_1 = require("services");
const ModalQuoteGenerated = ({ quoteId, ...rest }) => {
    const linkRef = React.useRef(null);
    const [pdfURL, setPdfURL] = React.useState(null);
    const onDownloadPress = () => {
        const onSuccess = (blob) => {
            const url = URL.createObjectURL(blob);
            const url2 = URL.createObjectURL(blob);
            setPdfURL(url + "d");
            setPdfURL(url2);
            if (linkRef.current)
                linkRef.current.download = `Cotización_${quoteId}`;
            linkRef.current?.click();
        };
        (0, services_1.doQuoteDownload)({
            params: {
                data: {
                    cotiid: quoteId,
                },
            },
            callback: {
                onSuccess,
            },
        });
    };
    return (<native_base_1.Modal {...rest}>
			<native_base_1.Modal.Content>
				<native_base_1.Modal.CloseButton />

				<native_base_1.Modal.Body>
					<native_base_1.View style={styles.modal} mt={{ base: 20, md: 40 }}>
						<native_base_1.View style={styles.imgQuotesLayout}>
							<native_base_1.Image style={styles.imgQuotes} source={require("images/ic_quotes.svg")}/>
						</native_base_1.View>
						<native_base_1.Text style={styles.txtQuote}>Cotización</native_base_1.Text>
						<native_base_1.Text style={styles.txtQuoteNumber}>
							Nº {quoteId ?? ""}
						</native_base_1.Text>
					</native_base_1.View>
				</native_base_1.Modal.Body>
				<native_base_1.Modal.Footer>
					{pdfURL && (<native_base_1.Link isExternal={true} display="none" href={pdfURL} ref={linkRef}>
							Download
						</native_base_1.Link>)}
					<native_base_1.Button.Group direction="column" space="10" w={{ base: "100%", md: "auto" }}>
						<native_base_1.Button onPress={onDownloadPress} endIcon={<native_base_1.Image source={require("images/ic_download.svg")} variant="iconButtonRight" alt={theme_1.Str.alt.ic_download}/>} w={{ base: "100%", md: "auto" }} textTransform="uppercase">
							Descargar
						</native_base_1.Button>

						{/*
        <Button
            endIcon={
                <Image
                    source={require("images/ic_share.svg")}
                    variant="iconButtonRight"
                    alt={Str.alt.ic_share}
                />
            }
            colorScheme={Colors.btnSecondary.name}
            w={{base: "100%", md: "auto"}}
            textTransform="uppercase">
            Compartir
        </Button>
        */}
					</native_base_1.Button.Group>
				</native_base_1.Modal.Footer>
			</native_base_1.Modal.Content>
		</native_base_1.Modal>);
};
const styles = react_native_1.StyleSheet.create({
    modal: {
        backgroundColor: theme_1.default.Colors.colorModalBg,
        borderColor: theme_1.default.Colors.colorModalBorder,
        borderRadius: theme_1.default.Sizes.modalRadius,
        borderWidth: theme_1.default.Sizes.modalBorderWidth,
        padding: 20,
        alignItems: "center",
    },
    imgQuotesLayout: {
        backgroundColor: theme_1.default.Colors.colorVeryLightGray1,
        borderRadius: 60,
        padding: 15,
    },
    imgQuotes: {
        width: 40,
        height: 40,
    },
    txtQuote: {
        ...theme_1.default.TextStyles.modalTitle,
        marginTop: 20,
    },
    txtQuoteNumber: {
        marginTop: 6,
    },
    imgDownload: {
        width: 13,
        height: 13,
    },
});
exports.default = ModalQuoteGenerated;
