/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * @format
 * @flow strict-local
 */

import React, {useEffect, useState} from "react";
import {NativeBaseProvider, View} from "native-base";

import {theme} from "theme";
import {makeAsyncComponent} from "./async_load";

const LazyNavigator = React.lazy(() => import("components/Navigator"));
const Navigator = makeAsyncComponent(
	LazyNavigator,
	<View bg="#fdf103" w="100%" h="100%"></View>,
);

const App = () => {
	/*const [isLoading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1);
	});

	if (isLoading)
		return (
			<NativeBaseProvider theme={theme}>
				<SplashScreen />
			</NativeBaseProvider>
		);
*/
	return (
		<>
			<NativeBaseProvider theme={theme}>
				<Navigator />
			</NativeBaseProvider>
		</>
	);
};

export default App;
