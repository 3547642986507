import Colors from "./../colors";

export default {
	baseStyle: {
		_text: {
			ml: 10,
		},
		_icon: {},
	},
	sizes: {
		lg: {_icon: {size: 22}, _text: {fontSize: "xl"}},
		md: {_icon: {size: 18}, _text: {fontSize: "lg"}},
		sm: {_icon: {size: 14}, _text: {fontSize: "md"}},
	},
	defaultProps: {
		size: "sm",
		colorScheme: Colors.radioPrimary.name,
	},
};
