import {Platform, Text, Image, TextInput} from "react-native";
import {TextStyles, Sizes} from "theme";
if (Platform.OS === "android") {
	//require('harmony-reflect');
}

const configureText = () => {
	// Set a global font for
	const defaultFontFamily = {
		style: {
			...TextStyles.body3,
		},
	};
	const TextRender = Text.render;
	//console.log(`ImageRender=${Image.render}`)
	const initialDefaultProps = Text.defaultProps;
	Text.defaultProps = {
		...initialDefaultProps,
		...defaultFontFamily,
	};
	Text.render = function render(props, ...args) {
		const oldProps = props;
		let newProps = {
			...props,
			style: [defaultFontFamily.style, props.style],
		};
		try {
			return Reflect.apply(TextRender, this, [newProps, ...args]);
		} finally {
			newProps = oldProps;
		}
	};
};

const configureTextInput = () => {
	// Set a global font for
	const defaultFontFamily = {
		style: {
			...TextStyles.body3,
			minHeight: Sizes.inputMinHeight,
		},
	};
	const TextInputRender = TextInput.render;
	//console.log(`ImageRender=${Image.render}`)
	const initialDefaultProps = TextInput.defaultProps;
	TextInput.defaultProps = {
		...initialDefaultProps,
		...defaultFontFamily,
	};
	TextInput.render = function render(props, ...args) {
		const oldProps = props;
		let newProps = {
			...props,
			style: [defaultFontFamily.style, props.style],
		};
		try {
			return Reflect.apply(TextInputRender, this, [newProps, ...args]);
		} finally {
			newProps = oldProps;
		}
	};
};

const configureImage = () => {
	const defaultResizeMode = {
		style: {
			resizeMode: "center",
		},
	};

	const ImageRender = Image.render;
	//console.log(`ImageRender=${Image.render}`)
	const initialDefaultProps = Image.defaultProps;
	Image.defaultProps = {
		...initialDefaultProps,
		...defaultResizeMode,
	};
	Image.render = function render(props, ...args) {
		const oldProps = props;
		let newProps = {
			...props,
			style: [defaultResizeMode.style, props.style],
		};
		try {
			return Reflect.apply(ImageRender, this, [newProps, ...args]);
		} finally {
			newProps = oldProps;
		}
	};
};

export default function () {
	//configureText();
	//configureTextInput();
	configureImage();
}
