"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const native_base_1 = require("native-base");
const theme_1 = require("theme");
const ModalSelectSupportIncidentSetup = ({ setup, onSetupOptionPress, ...rest }) => {
    const renderItem = ({ item, }) => (<native_base_1.Pressable onPress={() => {
            onSetupOptionPress(item);
        }} mb="10" borderWidth={1} borderColor="colorLightGray1" borderRadius={theme_1.Sizes.configRadius} flexDirection="row" alignItems="center">
				<native_base_1.Text py={10} flex="1" mx="10" variant="body4">
					{item.nombre ?? ""}
				</native_base_1.Text>
			</native_base_1.Pressable>);
    return (<native_base_1.Modal {...rest}>
				<native_base_1.Modal.Content>
					<native_base_1.Modal.CloseButton />
					<native_base_1.Modal.Header>{`Tipo de ${setup?.nombre ?? ""}`}</native_base_1.Modal.Header>
					<native_base_1.Modal.Body>
						<native_base_1.FlatList data={setup?.opciones} renderItem={renderItem} mb="10"/>
					</native_base_1.Modal.Body>
				</native_base_1.Modal.Content>
			</native_base_1.Modal>);
};
exports.default = ModalSelectSupportIncidentSetup;
