const _Colors = {
	colorVividYellow1: "#fdf103",
	colorPureYellow1: "#ffcc00",
	colorPureYellow2: "#FFEC00",
	colorVividRed1: "#ed1c24",
	colorLimeGreen1: "#009245",
	colorLimeGreen2: "#7DD686",
	colorModerateGreen1: "#8cc63f",
	colorDarkGray1: "#808080",
	colorGray1: "#b3b3b3",
	colorLightGray1: "#cccccc",
	colorLightGrayishBlue2: "#DBDDE4",
	colorLightGrayishBlue1: "#f6f6fb",
	colorVeryLightGray1: "#f2f2f2",
	colorVeryLightGray2: "#f7f7f7",
	colorVeryLightGray3: "#e6e6e6",
};

const ToastColors = {
	colorToastError: _Colors.colorVividRed1,
};

const Colors = {
	primary: {
		50: "#ffffdb",
		100: "#fefbad",
		200: "#fef87e",
		300: "#fef54c",
		400: "#fdf21b",
		500: "#e4d902",
		600: "#b1a900",
		700: "#7e7800",
		800: "#4c4800",
		900: "#1a1800",
	},
	secondary: {
		50: "#f2f2f2",
		100: "#d9d9d9",
		200: "#bfbfbf",
		300: "#a6a6a6",
		400: "#8c8c8c",
		500: "#737373",
		600: "#595959",
		700: "#404040",
		800: "#262626",
		900: "#0d0d0d",
	},
	accent: {
		50: "#f2f2f2",
		100: "#d9d9d9",
		200: "#bfbfbf",
		300: "#a6a6a6",
		400: "#8c8c8c",
		500: "#737373",
		600: "#595959",
		700: "#404040",
		800: "#262626",
		900: "#0d0d0d",
	},
	btnPrimary: {
		name: "btnPrimary", //requirido! debe ser el mismo nombre del obj, se usa para definir los estilos en las variantes, //use this in 'colorScheme' button prop
		50: "#ffffdb",
		100: "#fefbad",
		200: "#fef87e",
		300: "#fef54c",
		400: "#fdf21b",
		500: "#fdf103", //bg
		600: "#e4d902", //hover //focus
		700: "#b1a900", //pressed
		800: "#4c4800",
		900: "#1a1800",
	},
	btnSecondary: {
		name: "btnSecondary", //use this in 'colorScheme' button prop
		50: "#f2f2f2",
		100: "#d9d9d9",
		200: "#bfbfbf",
		300: "#a6a6a6",
		400: "#8c8c8c",
		500: "#000000", //bg
		600: "#595959", //hover //focus
		700: "#404040", //pressed
		800: "#262626",
		900: "#0d0d0d",
	},
	btnWhite: {
		name: "btnWhite", //use this in 'colorScheme' button prop
		50: "#f2f2f2",
		100: "#d9d9d9",
		200: "#bfbfbf",
		300: "#a6a6a6",
		400: "#8c8c8c",
		500: "#ffffff", //bg
		600: "#f2f2f2", //hover //focus
		700: "#d9d9d9", //pressed
		800: "#262626",
		900: "#0d0d0d",
	},
	radioPrimary: {
		name: "radioPrimary", //use this in 'colorScheme' radio prop
		50: "#f2f2f2",
		100: "#d9d9d9",
		200: "#cccccc", // bg focus // bg presed
		300: "#a6a6a6",
		400: "#8c8c8c",
		500: "#737373",
		600: "#0d0d0d", // border checked
		700: "#404040",
		800: "#262626",
		900: "#0d0d0d",
	},
	colorPrimaryDark: "#ffffff",
	colorAccent: "#000000",

	//usages
	colorTextPrimary: "#000000",
	colorLine: _Colors.colorVeryLightGray1,

	colorBtnPrimaryBg: _Colors.colorVividYellow1,
	colorBtnPrimaryText: "black",
	colorBtnPrimaryIcon: "black",

	colorBtnSecondaryBg: "black",
	colorBtnSecondaryText: _Colors.colorVividYellow1,
	colorBtnSecondaryIcon: _Colors.colorVividYellow1,

	colorModalBg: "white",
	colorModalBorder: "transparent",
	..._Colors,
	...ToastColors,
};

export default Colors;
