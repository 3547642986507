// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./../src/assets/fonts/din_pro_bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./../src/assets/fonts/din_pro.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./../src/assets/fonts/din_black.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./../src/assets/fonts/din_medium.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: \"din_pro_bold\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n    font-family: \"din_pro\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n@font-face {\n    font-family: \"din_black\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n\n@font-face {\n    font-family: \"din_medium\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n\n", "",{"version":3,"sources":["webpack://./web/fonts.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,4CAAkD;AACtD;;AAEA;IACI,sBAAsB;IACtB,4CAA6C;AACjD;;AAEA;IACI,wBAAwB;IACxB,4CAA+C;AACnD;;AAEA;IACI,yBAAyB;IACzB,4CAAgD;AACpD","sourcesContent":["@font-face {\n    font-family: \"din_pro_bold\";\n    src: url(\"./../src/assets/fonts/din_pro_bold.otf\");\n}\n\n@font-face {\n    font-family: \"din_pro\";\n    src: url(\"./../src/assets/fonts/din_pro.otf\");\n}\n\n@font-face {\n    font-family: \"din_black\";\n    src: url(\"./../src/assets/fonts/din_black.otf\");\n}\n\n@font-face {\n    font-family: \"din_medium\";\n    src: url(\"./../src/assets/fonts/din_medium.otf\");\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
