import React, {useEffect, useState} from "react";
import {StyleSheet} from "react-native";
import {View, Modal, Text, FlatList, Pressable} from "native-base";

import {Str, Colors, Sizes} from "theme";
import {doClientes} from "services";

const ModalSelectClient = props => {
	const {onSelectedClient, ...rest} = props;

	const [clients, setClients] = useState(null);
	useEffect(() => {
		const onSuccess = response => {
			setClients(response?.data);
		};
		doClientes({
			callback: {
				onSuccess: onSuccess,
			},
		});
	}, []);

	const renderItem = ({item}) => (
		<Pressable
			onPress={() => {
				onSelectedClient(item);
			}}>
			<Text py={10}>{`${item.nombre || ""} ${item.id}`}</Text>
		</Pressable>
	);

	return (
		<Modal {...rest}>
			<Modal.Content>
				<Modal.CloseButton />
				<Modal.Header></Modal.Header>
				<Modal.Body>
					<View style={styles.modal}>
						<FlatList
							data={clients}
							renderItem={renderItem}
							keyExtractor={item => item.id}
						/>
					</View>
				</Modal.Body>
				<Modal.Footer></Modal.Footer>
			</Modal.Content>
		</Modal>
	);
};

const styles = StyleSheet.create({
	modal: {
		backgroundColor: Colors.colorModalBg,
		borderColor: Colors.colorModalBorder,
		borderRadius: Sizes.modalRadius,
		borderWidth: Sizes.modalBorderWidth,
	},
});

export default ModalSelectClient;
