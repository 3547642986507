export const Alert = {
	baseStyle: {
		p: 12,
	},
};

// AlertIcon

export const AlertIcon = {
	baseStyle: {
		size: 16,
	},
};
