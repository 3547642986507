import React from "react";
import {Image} from "native-base";

export const Footer = () => {
	return <></>;
	/*return (
		<Image
			w="100%"
			h="2%"
			resizeMode="cover"
			source={require("images/img_footer.png")}
			alt="footer"
		/>
	);*/
};
