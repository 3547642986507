import Sizes from "./../sizes";
import Fonts from "./../fonts";

export default {
	baseStyle: {
		bg: "white",
		borderRadius: Sizes.inputRadius,
		borderColor: "black",
		borderWidth: 2,
		fontFamily: Fonts.regular,
		_focus: null,
	},
	variants: {
		rounded: () => {
			return {
				borderRadius: "40",
			};
		},
	},
	sizes: {
		"2xl": {
			fontSize: "xl",
			px: Sizes.inputPaddingHorizontal,
			py: 16,
		},
		xl: {fontSize: "lg", px: Sizes.inputPaddingHorizontal, py: 14},
		lg: {fontSize: "md", px: Sizes.inputPaddingHorizontal, py: 12},
		md: {fontSize: "sm", px: Sizes.inputPaddingHorizontal, py: 10},
		sm: {fontSize: "xs", px: Sizes.inputPaddingHorizontal, py: 8},
		xs: {fontSize: "2xs", px: Sizes.inputPaddingHorizontal, py: 7},
	},
	defaultProps: {
		size: {
			base: "md",
			md: "lg",
		},
	},
};
