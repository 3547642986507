"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const react_native_1 = require("react-native");
const native_base_1 = require("native-base");
const theme_1 = __importStar(require("theme"));
const services_1 = require("services");
const context_1 = require("components/context");
const ModalQuoteDiscount = props => {
    const { warehouse, warehouseRV, discounts, onApplySuccess, ...rest } = props;
    const newQuoteContext = React.useContext(context_1.NewQuoteContext);
    const discountsRequest = React.useRef();
    React.useEffect(() => {
        discountsRequest.current = discounts?.map?.(discount => ({
            linea: discount.id,
            desc: discount.descuento,
            descAdd: discount.descuentoAdd,
        }));
    }, [discounts]);
    const onEnterNewDiscountValue = (value, discountId) => {
        if (discountsRequest.current) {
            const discount = discountsRequest.current.find(discount => discount.linea == discountId);
            if (discount) {
                discount.desc = value;
            }
        }
    };
    const onEnterNewDiscountValueAdditional = (value, discountId) => {
        if (discountsRequest.current) {
            const discount = discountsRequest.current.find(discount => discount.linea == discountId);
            if (discount) {
                discount.descAdd = value;
            }
        }
    };
    const renderItem = ({ item }) => (<Discount title={item.name} discount={item.descuento} discountAdditional={item.descuentoAdd} onEnterNewDiscountValue={value => onEnterNewDiscountValue(value, item.id)} onEnterNewDiscountValueAdditional={value => onEnterNewDiscountValueAdditional(value, item.id)}/>);
    const onApplyPress = () => {
        const onSuccess = (response) => {
            onApplySuccess();
        };
        (0, services_1.doCarritoCotiDiscounts)({
            params: {
                cliid: newQuoteContext?.cliid ?? "",
                almacen: newQuoteContext?.isNewRV() == true
                    ? `${warehouseRV?.docentry ?? ""}-${warehouseRV?.lineid ?? ""}`
                    : warehouse?.codigo,
                tipo: newQuoteContext?.isNewRV() == true ? "cotirvmt" : "coti",
                data: discountsRequest.current?.filter(discountRequest => discountRequest.desc || discountRequest.descAdd),
            },
            callback: {
                onSuccess,
            },
        });
    };
    return (<native_base_1.Modal {...rest}>
			<native_base_1.Modal.Content>
				<native_base_1.Modal.CloseButton />
				<native_base_1.Modal.Header>{theme_1.Str.enterTheDiscountToApply}</native_base_1.Modal.Header>
				<native_base_1.Modal.Body>
					<native_base_1.View style={styles.modal}>
						<native_base_1.FlatList data={discounts} renderItem={renderItem}/>
					</native_base_1.View>
				</native_base_1.Modal.Body>
				<native_base_1.Modal.Footer>
					<native_base_1.Button onPress={onApplyPress} w={{ base: "100%", md: "auto" }} minW={{ base: "100%", md: "180" }} textTransform="uppercase">
						Aplicar
					</native_base_1.Button>
				</native_base_1.Modal.Footer>
			</native_base_1.Modal.Content>
		</native_base_1.Modal>);
};
const Discount = ({ title, discount, discountAdditional, onEnterNewDiscountValue, onEnterNewDiscountValueAdditional, }) => {
    const [discountValue, setDiscountValue] = React.useState(discount);
    const onHandleChange = (value) => {
        onEnterNewDiscountValue(value);
        setDiscountValue(value);
    };
    const [discountValueAdditional, setDiscountValueAdditional] = React.useState(discountAdditional);
    const onHandleChangeAdditional = (value) => {
        onEnterNewDiscountValueAdditional(value);
        setDiscountValueAdditional(value);
    };
    return (<native_base_1.View style={discountStyles.layoutDiscount} mt={{ base: 10, md: 15 }} px={{ base: 10, md: 15 }} py={{ base: 8, md: 14 }}>
			<native_base_1.Text style={discountStyles.txtTitle}>{title}</native_base_1.Text>
			<native_base_1.VStack alignItems="center" ml={10}>
				<native_base_1.Text>D1</native_base_1.Text>
				<native_base_1.Input style={discountStyles.inputDiscount} size={{ base: "sm", md: "md" }} onChangeText={onHandleChange} value={discountValue}/>
			</native_base_1.VStack>
			<native_base_1.VStack alignItems="center" ml={10}>
				<native_base_1.Text>D2</native_base_1.Text>
				<native_base_1.Input style={discountStyles.inputDiscount} size={{ base: "sm", md: "md" }} onChangeText={onHandleChangeAdditional} value={discountValueAdditional}/>
			</native_base_1.VStack>
		</native_base_1.View>);
};
const styles = react_native_1.StyleSheet.create({
    modal: {
        backgroundColor: theme_1.default.Colors.colorModalBg,
        borderColor: theme_1.default.Colors.colorModalBorder,
        borderRadius: theme_1.default.Sizes.modalRadius,
        borderWidth: theme_1.default.Sizes.modalBorderWidth,
    },
});
const discountStyles = react_native_1.StyleSheet.create({
    layoutDiscount: {
        flexDirection: "row",
        alignItems: "center",
        borderColor: theme_1.default.Colors.colorVeryLightGray3,
        borderWidth: 1,
        borderRadius: theme_1.default.Sizes.configRadius,
    },
    txtTitle: {
        flex: 1,
    },
    inputDiscount: {
        width: 60,
        borderWidth: 1.5,
        borderColor: "black",
        borderRadius: theme_1.default.Sizes.configRadius,
    },
});
exports.default = ModalQuoteDiscount;
