import {AppRegistry} from "react-native";
import App from "./src/components/App";
import "web/fonts"; //solo web
import setDefaults from "./src/utils/defaults";

setDefaults();

AppRegistry.registerComponent("App", () => App);
AppRegistry.runApplication("App", {
	initialProps: {},
	rootTag: document.getElementById("root"),
});

if ("serviceWorker" in navigator) {
	window.addEventListener("load", () => {
		navigator.serviceWorker
			//.register("/service-worker.js")
			//.register("/firebase-messaging-sw.js")
			.then(registration => {
				console.log("SW registered: ", registration);
			})
			.catch(registrationError => {
				console.log("SW registration failed: ", registrationError);
			});
	});
} else {
	console.log("serviceWorker in navigator == false");
}
