export default {
	baseStyle: {
		_checked: {
			borderColor: "colorAccent",
			bg: "colorAccent",
		},
	},
	sizes: {
		lg: {_icon: {size: 24}, _text: {fontSize: "xl"}},
		md: {_icon: {size: 20}, _text: {fontSize: "lg"}},
		sm: {_icon: {size: 16}, _text: {fontSize: "md"}},
	},
};
