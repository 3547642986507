import React from "react";
import {ZStack, View, Image} from "native-base";

import {Footer} from "components/partial/template";

export const Content = ({children}) => {
	return (
		<ZStack w="100%" h="100%" justifyContent="flex-end">
			<Image
				h="100%"
				w="100%"
				resizeMode="cover"
				source={require("images/img_thunders_dashboard.png")}
				alt="content-back"
			/>

			<ZStack w="100%" h="100%">
				{children}
			</ZStack>

			<Footer />
		</ZStack>
	);
};
