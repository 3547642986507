import Sizes from "./../sizes";

export default {
	variants: {
		iconInputRight: () => {
			return {
				w: Sizes.inputIconSize,
				h: Sizes.inputIconSize,
				mr: Sizes.inputPaddingHorizontal,
			};
		},
		iconInputLeft: () => {
			return {
				w: Sizes.inputIconSize,
				h: Sizes.inputIconSize,
				ml: Sizes.inputPaddingHorizontal,
			};
		},
		iconButtonRight: () => {
			return {
				w: Sizes.btnIconSize,
				h: Sizes.btnIconSize,
			};
		},
		iconButtonLeft: () => {
			return {
				w: Sizes.btnIconSize,
				h: Sizes.btnIconSize,
			};
		},
		iconButtonRightSm: () => {
			return {
				w: Sizes.btnIconSizeSm,
				h: Sizes.btnIconSizeSm,
			};
		},
		iconButtonLeftSm: () => {
			return {
				w: Sizes.btnIconSizeSm,
				h: Sizes.btnIconSizeSm,
			};
		},
		iconButtonRightXs: () => {
			return {
				w: Sizes.btnIconSizeXs,
				h: Sizes.btnIconSizeXs,
			};
		},
		iconButtonLeftXs: () => {
			return {
				w: Sizes.btnIconSizeXs,
				h: Sizes.btnIconSizeXs,
			};
		},
	},
};
