const Sizes = {
	//configs
	configRadius: 13,
	configRadiusSm: 8,
	configElevation: 4,

	//modal
	modalRadius: 15,
	modalBorderWidth: 0,

	//button
	btnMinHeight: 50,
	btnRadius: 12,
	btnIconSize: 15,
	btnIconSizeSm: 12,
	btnIconSizeXs: 10,
	btnIconMargin: 10, //space between text and icon
	btnBorderSize: 0,

	btnSmMinHeight: 25,
	btnSmRadius: 5,
	btnSmIconSize: 10,
	btnSmBorderSize: 0,

	//input
	inputMinHeight: 50,
	inputRadius: 12,
	inputElevation: 0,
	inputIconPadding: 10,
	inputIconSize: 16,
	inputPaddingHorizontal: 15,
	inputPaddingVertical: 5,
	inputBorderWidth: 1,

	//font
	h1: 30,
	h2: 22,
	h3: 16,
	h4: 14,
	h5: 12,

	body1: 30,
	body2: 20,
	body3: 16,
	body4: 14,
	body5: 12,
};

export default Sizes;
