"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.State = exports.Type = void 0;
var Type;
(function (Type) {
    Type["CHILD"] = "child";
    Type["PARENT"] = "parent";
})(Type = exports.Type || (exports.Type = {}));
var State;
(function (State) {
    State["OPEN"] = "1";
    State["CLOSE"] = "0";
})(State = exports.State || (exports.State = {}));
