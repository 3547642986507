const FNC_ADD = "add";
const FNC_REMOVE = "remove";
const FNC_CLEAR = "clear";
const FNC_LIST = "list";
const FNC_AFFECT = "afectacion";
const FNC_PRICE = "precio";
const FNC_DISCOUNTS = "descuentos";
const FNC_MERCH = "merch";
const FNC_SOBRE_DISCOUNT = "sobredescuento";

const COND_FREE = "free";
const COND_GRAV = "grav";

export default {
	FNC_ADD,
	FNC_REMOVE,
	FNC_CLEAR,
	FNC_LIST,
	FNC_AFFECT,
	FNC_PRICE,
	FNC_DISCOUNTS,
	FNC_MERCH,
	FNC_SOBRE_DISCOUNT,
	COND_FREE,
	COND_GRAV,
};
