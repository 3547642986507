import {API, StorageKey} from "constants";
import AsyncStorage from "@react-native-async-storage/async-storage";

const doClienteDirecciones = async ({params, callback}) => {
	const loginDataStr = await AsyncStorage.getItem(StorageKey.LOGIN_DATA);
	const loginDataJSON = JSON.parse(loginDataStr);

	const clientDataStr = await AsyncStorage.getItem(StorageKey.CLIENT_DATA);
	const clientDataJSON = JSON.parse(clientDataStr);

	var formData = new FormData();
	formData.append(
		"data",
		JSON.stringify({
			op: "clientedirs",
			token: loginDataJSON.token,
			data: {
				uid: params?.data?.uid ?? clientDataJSON.id, //id del cliente,
				tipo: params?.tipo,
			},
		}),
	);
	fetch(API.URL_BASE, {
		method: "POST",
		headers: {
			...API.HEADER_BASIC_AUTH,
		},
		body: formData,
	})
		.then(response => response.json())
		.then(json => {
			switch (json.status) {
				case API.WS_STATUS_SUCCESS:
					callback.onSuccess(json);
					break;

				default:
					callback.onFail?.(json.msg);
					break;
			}
		})
		.catch(error => {
			console.error(error);
			callback?.onFail("Error!");
		});
};

export default doClienteDirecciones;
