import Fonts from "./../fonts";

export default {
	baseStyle: {
		fontFamily: Fonts.bold,
		color: "colorTextPrimary",
	},
	variants: {
		h1: {
			fontSize: {
				base: "xl",
				sm: "2xl",
				md: "4xl",
			},
		},
	},
	defaultProps: null,
};
